import React from "react"
import PropTypes from "prop-types"

import "../styles/components/section-title.scss"

const SectionTitle = ({
  title,
  titleColor,
  subtitle,
  subtitleColor,
  className,
  subtitleWidth,
}) => {
  return (
    <div
      className={`section-title-wrapper has-text-centered has-margin-bottom-6 ${className}`}
    >
      <h4 className={`title  ${titleColor ? `has-text-${titleColor}` : ""}`}>
        {title}
      </h4>
      <p
        style={{ maxWidth: subtitleWidth, margin: "0 auto", fontSize: 18 }}
        className={`subtitle ${
          subtitleColor ? `has-text-${subtitleColor}` : ""
        }`}
      >
        {subtitle}
      </p>
    </div>
  )
}
SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  subtitleColor: PropTypes.string,
  isDark: PropTypes.bool,
  className: PropTypes.string,
  subtitleWidth: PropTypes.number,
}
SectionTitle.defaultProps = {
  titleColor: "",
  subtitleColor: "",
  className: "",
  subtitleWidth: 480,
}
export default SectionTitle
