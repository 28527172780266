import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Components
import SectionTitle from "../components/section-title"

// Icons
import CardsIcon from "../assets/icons/cards.svg"
import BlocksIcon from "../assets/icons/block.svg"
import ClockIcon from "../assets/icons/clock.svg"
import GrowIcon from "../assets/icons/grow.svg"
import FireIcon from "../assets/icons/fire.svg"
import NoteIcon from "../assets/icons/note.svg"

// Logos
import VisaLogo from "../assets/logos/visa.svg"
import MastercardLogo from "../assets/logos/mastercard.svg"

// Styles
import "../styles/pages/payment-modules.scss"

const HeroSection = ({ imageFluid }) => {
  const { t } = useTranslation()
  return (
    <section className="section is-hero has-margin-bottom-6">
      <div className="container">
        <div className="columns is-vcentered is-variable is-8">
          <div className="column">
            <h1 className="title has-text-white is-spaced is-2 ">
              {t("World-Class Security for Online Payments")}
            </h1>
            <h2 className="subtitle has-text-white has-margin-bottom-6">
              {t(
                "As a PCI-DSS Level 1 Payment Service Provider, Twispay will assist you in minimising your security risks when accepting credit card payments. Continue reading to discover all the security benefits entailed by your Twispay merchant account."
              )}
            </h2>
          </div>
          <div className="column">
            <Img fluid={imageFluid} />
          </div>
        </div>
      </div>
    </section>
  )
}

const QuestionSection = () => {
  const { t } = useTranslation()
  const QAs = [
    {
      question: t("What Exactly Is PCI DSS?"),
      answer: [
        t(
          "PCI DSS is a set of technical and operational requirements designed to maximise cardholder data protection. The standard has been put forth and is periodically updated by the credit card networks, the most notorious of which are Visa, MasterCard and American Express."
        ),
        t(
          "The Payment Card Industry Security Standards Council (PCI SSC) is the official regulatory institution that enforces the best-practice guidelines for securing e-commerce and PCI compliance."
        ),
        t(
          "Twispay is a PCI-DSS Level 1 Payment Service Provider. You are in good hands."
        ),
      ],
    },
    {
      question: t("Do I Need to Be Compliant?"),
      answer: [
        t(
          "The short answer is YES. Every merchant that accepts online credit-card payments is required to be PCI-DSS compliant."
        ),
        t(
          "Even though Twispay will be in charge of collecting, processing, and storing encrypted cardholder data, you must still complete a few PCI-DSS compliance measures."
        ),
        t(
          "The great news is that, for an annual online transaction volume that does not exceed 6 million transactions, you can ensure compliance by filling out a simple self-assessment questionnaire (SAQ), and allowing Twispay to handle everything else."
        ),
      ],
    },
    {
      question: t("The Best and Quickest Solution?"),
      answer: [
        t(
          "It is called iFrame. The latest updates to the PCI DSS standards clearly state that using an iFrame results in minimum PCI requirements on the part of the merchant. Here's a key section of the document:"
        ),
        t(
          "“At present, a merchant implementing an e-commerce solution that uses iFrames to load all payment content from a PCI-DSS compliant service provider may be eligible to assess its PCI compliance using a reduced list of controls identified in SAQ A, the smallest possible subset of PCI-DSS requirements, because most of the PCI DSS requirements are outsourced to the Payment Service Provider (PSP).”"
        ),
      ],
    },
  ]
  return (
    <section className="section">
      <div className="container">
        <div className="columns">
          {QAs.map(QA => (
            <div key={QA.question} className="column">
              <h4 className="title is-5">{QA.question}</h4>
              {QA.answer.map(paragraph => (
                <React.Fragment key={paragraph}>
                  <p className="has-text-secondary">{paragraph}</p>
                  <br />
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const SecureSection = () => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <CardsIcon />,
      title: t("European Companies"),
      description: t(
        "Twispay is licensed to provide payment services within the EEA"
      ),
    },
    {
      icon: <BlocksIcon />,
      title: t("Secure Dashboard"),
      description: t(
        "One place to manage all your merchant finances, quickly, safely and with ease"
      ),
    },
    {
      icon: <ClockIcon />,
      title: t("24/7 Fraud Monitoring"),
      description: t(
        "Twispay provides a state-of-the-art alert system, using powerful monitoring algorithms"
      ),
    },
    {
      icon: <GrowIcon />,
      title: t("Safe & Continuous Optimisation"),
      description: t(
        "Safely and quickly customise your checkout to boost sales and conversion rates"
      ),
    },
    {
      icon: <FireIcon />,
      title: t("World-Class Security Protocols"),
      description: t(
        "From smart scoring to automatic thresholds and blacklisting, Twispay will keep you secure"
      ),
    },
    {
      icon: <NoteIcon />,
      title: t("Worldwide Cardholders"),
      description: t(
        "Safely process payments in any currency from customers across the entire globe"
      ),
    },
  ]
  return (
    <section className="section has-margin-bottom-6">
      <div className="container">
        <SectionTitle
          title={t("Security Above All")}
          subtitle={t(
            "Focus on your business while we protect you from online fraudulent activities"
          )}
        />
        <div className="columns has-margin-top-6 has-text-centered is-multiline">
          {items.map(item => (
            <div
              key={item.description}
              className="column is-one-third has-margin-bottom-6 "
            >
              <span className="icon is-larger has-margin-bottom-5 has-text-primary">
                {item.icon}
              </span>
              <h4 className="title is-4 ">{item.title}</h4>
              <p className="subtitle is-5 has-text-secondary">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const FlexiableSection = ({ imageFluid }) => {
  const { t } = useTranslation()
  return (
    <section className="section smooth-section has-margin-bottom-5">
      <div className="container">
        <SectionTitle
          title={t("Flexible and Secure Payment Services")}
          subtitle={t(
            "Twispay provides you with a flexible and secure, PCI-DSS compliant platform connecting you as our merchant to a myriad of functionalities and service providers"
          )}
        />
        <div className="columns is-flex is-centered-centered is-mobile has-margin-bottom-5">
          <div className="column is-narrow">
            <img width="100" src={VisaLogo} alt="VISA" />
          </div>
          <div className="column is-narrow">
            <img width="100" src={MastercardLogo} alt="Mastercard" />
          </div>
        </div>
        <div className="columns  is-centered">
          <div className="column is-three-fifths">
            <Img fluid={imageFluid} className="has-margin-bottom-4" />
          </div>
        </div>
      </div>
    </section>
  )
}

const PciPage = ({ data: images }) => {
  const { t } = useTranslation()
  return (
    <Layout
      stripeClass="is-blue-violet"
      pageName="secure-online-payment-services-pci-dss"
    >
      <SEO title={t("Secure Online Payment Services")} />
      <HeroSection imageFluid={images.pcivmImage.childImageSharp.fluid} />
      <QuestionSection />
      <SecureSection />
      <FlexiableSection imageFluid={images.secureImage.childImageSharp.fluid} />
    </Layout>
  )
}

export const images = graphql`
  query {
    pcivmImage: file(relativePath: { eq: "pcivm.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    secureImage: file(relativePath: { eq: "payment-modules-secure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default PciPage
