/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        file(relativePath: { eq: "twispay-social-media.png" }) {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description

  const defaultMeta = [
    { name: `description`, content: metaDescription },
    { property: `og:title`, content: title },
    { property: `og:description`, content: metaDescription },
    { property: `og:type`, content: `website` },
    { property: `og:image`, content: `https://www.twispay.com${file.childImageSharp.original.src}` },
    { name: `twitter:card`, content: `summary` },
    { name: `twitter:creator`, content: site.siteMetadata.author },
    { name: `twitter:title`, content: title },
    { name: `twitter:description`, content: metaDescription },
    { property: `twitter:image`, content: `https://www.twispay.com${file.childImageSharp.original.src}` },
  ].map(item => meta.find(newMeta => (
    (newMeta.name && newMeta.name === item.name) ||
    (newMeta.property && newMeta.property === item.property)
  )) || item)

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      meta={defaultMeta}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
